const SelectBox = (error, noBorder, height) => {
    let brandColor = "#6837ef";
    let errorColor = "#ff3364";
    // const storeStyle = store.getState().style;
    const isDarkMode = false;

    // border-radius: 0.625rem;
    // font-size: 16px;
    // letter-spacing: 0.25px;
    // padding-top: 17px;
    // padding-bottom: 17px;
    // height: 55px;

    let style = {
        control: (styles, { isFocused }) => ({
            ...styles,
            color: "#060213",
            backgroundColor: "#FFFFFF",
            height: height ? height : "55px !important",
            fontSize: "16px",
            letterSpacing: "0.25px",
            border: noBorder
                ? "none"
                : error
                    ? `1px solid ${errorColor} !important`
                    : `1px solid #dee1e5`,
            borderRadius: "10px !important",
            boxShadow: "none !important",
            textTransform: "capitalize",
            borderColor: isFocused ? brandColor : styles.borderColor,
            "&:hover": {
                borderColor: error
                    ? errorColor
                    : isFocused
                        ? brandColor
                        : "#dee1e5",
            },
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: isDarkMode
                ? state.isFocused
                    ? "#121212"
                    : "#393536"
                : styles.backgroundColor,
            textTransform: "capitalize",
            "&:hover": {
                color: isDarkMode ? "#FFF" : styles.color,
                backgroundColor: isDarkMode
                    ? "#121212"
                    : styles.backgroundColor,
            },
        }),
        valueContainer: (base) => ({
            ...base,
            // overflowX: 'scroll',
            flexWrap: "unset",
            paddingBotttom: "0",
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: "#FFF",
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
                borderRadius: "8px",
                padding: "2px 4px",
                // flex: '1 0 auto',
            };
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "#FFF",
            ":hover": {
                backgroundColor: data.color,
                color: "#FFF",
            },
        }),
        indicatorSeparator: () => ({ display: "none" }),
        noOptionsMessage: (styles) => ({
            ...styles,
            textAlign: "left",
            borderRadius: "4px !important",
        }),
        menu: (styles) => ({
            ...styles,
            // height: "50px !important",
            borderRadius: "4px 4px 0px 0px !important",
            fontSize: "16px",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            padding: '0 8px',
        }),
    };

    return style;
};

export { SelectBox };
