import React, { useMemo } from "react";
import ReactSelect from "react-select";
import { SelectBox } from "../DynamicSelect/style/selectBoxStyle";
import AsyncCreatableSelect from "react-select/creatable";
import SelectUICheckBoxOption from "./SelectUICheckBoxOption/SelectUICheckBoxOption";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import "./SelectUI.scss";

const SelectUI = ({
    error,
    handleChange,
    options,
    optionValueKey,
    optionLabelKey,
    label,
    defaultValue,
    isLoading,
    serverError,
    handleRetry,
    createAble,
    darkLabel,
    hasIcon,
    otherClassNames,
    isMultiSelection,
    hasOptionIcon,
    labelClass,
    closeMenuOnSelect,
    noBorder,
    isRequired,
    callApi,
    handleApiCall,
    height,
    marginClass,
    ...rest
}) => {
    const debouncedSearch = useMemo(
        () =>
            debounce((search) => handleApiCall?.(search), 1000, {
                leading: true,
            }),
        // eslint-disable-next-line
        []
    );

    const handleSearch = (search) => {
        debouncedSearch(search);
    };

    const loadOptions = (inputValue, callback) => {
        setTimeout(() => {
            handleApiCall?.(inputValue);
            if (!isLoading) {
                callback?.(options);
            }
        }, 1000);
    };

    return (
        <div className={`form-group ${marginClass ? marginClass : "mb-3"}`}>
            {label && (
                <label
                    className={`select__label ${labelClass ? labelClass : ""} ${
                        darkLabel ? `dark__label` : "primary__label"
                    }`}>
                    {label}
                </label>
            )}
            {callApi ? (
                <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptions}
                    defaultOptions={options}
                    className={`select__box ${
                        otherClassNames ? otherClassNames : ""
                    }`}
                    classNamePrefix='react-select'
                    styles={SelectBox(error)}
                    onChange={({ value }, { name }) =>
                        handleChange?.(name, value)
                    }
                    getOptionLabel={(option) =>
                        optionLabelKey ? option[optionLabelKey] : option.label
                    }
                    getOptionValue={(option) =>
                        optionValueKey ? option[optionValueKey] : option.value
                    }
                    defaultValue={defaultValue}
                    isLoading={isLoading}
                    isMulti={isMultiSelection}
                    components={
                        hasOptionIcon && {
                            Option: SelectUICheckBoxOption,
                        }
                    }
                    {...rest}
                />
            ) : (
                <>
                    {createAble ? (
                        <AsyncCreatableSelect
                            className={`select__box ${
                                otherClassNames ? otherClassNames : ""
                            }`}
                            classNamePrefix='react-select'
                            styles={SelectBox(error)}
                            onChange={({ value }, { name }) =>
                                handleChange?.(name, value)
                            }
                            options={options}
                            getOptionLabel={(option) =>
                                optionLabelKey
                                    ? option[optionLabelKey]
                                    : option.label
                            }
                            getOptionValue={(option) =>
                                optionValueKey
                                    ? option[optionValueKey]
                                    : option.value
                            }
                            defaultValue={defaultValue}
                            isLoading={isLoading}
                            isMulti={isMultiSelection}
                            components={
                                hasOptionIcon && {
                                    Option: SelectUICheckBoxOption,
                                }
                            }
                            onInputChange={handleSearch}
                            {...rest}
                        />
                    ) : (
                        <ReactSelect
                            className={`select__box ${
                                otherClassNames ? otherClassNames : ""
                            }`}
                            classNamePrefix='react-select'
                            styles={SelectBox(error, noBorder, height)}
                            onChange={({ value }, { name }) =>
                                handleChange?.(name, value)
                            }
                            options={options}
                            getOptionLabel={(option) =>
                                optionLabelKey
                                    ? option[optionLabelKey]
                                    : option.label
                            }
                            getOptionValue={(option) =>
                                optionValueKey
                                    ? option[optionValueKey]
                                    : option.value
                            }
                            defaultValue={defaultValue}
                            isLoading={isLoading}
                            isMulti={isMultiSelection}
                            components={
                                hasOptionIcon && {
                                    Option: SelectUICheckBoxOption,
                                }
                            }
                            closeMenuOnSelect={closeMenuOnSelect}
                            {...rest}
                        />
                    )}
                </>
            )}
            {(serverError || error) && (
                <div>
                    <span className='select__ui--error-msg'>
                        {serverError ? serverError : error}
                    </span>
                    {` `}
                    {serverError && <span onClick={handleRetry}>Retry?</span>}
                </div>
            )}
        </div>
    );
};

export default SelectUI;
