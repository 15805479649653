import React, { useState, useContext, useEffect, useRef } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import AnalyticsFilters from "../common/AnalyticsFilters/AnalyticsFilters";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage, MonthDay } from "utils/helper";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
import {
    arrangeSolutionTypeData,
    computeDataFreq,
    computerPercentage,
    getCurrentAnalyticsCustomDates,
    getDatesBasedOnDuration,
} from "../common/helpers";
import "./AnalyticsTeamVolume.scss";
import API from "services/lib/api";
import {
    setAnalyticsChartData,
    updateCurrentAnalyticsFilter,
} from "store/analytics/actions";
import AnalyticsBarChart from "../common/AnalyticsBarChart/AnalyticsBarChart";
import {
    ANALYTICS_TEAM_VOL_REQ_TYPES,
    analyticsTeamVolDefaultOptions,
    analyticsTeamVolExtraTooltipMetrics,
} from "./enums";
import { teamAnalyticsRequestFilters } from "../common/enum";
// import Axios from "axios";

const { ERROR } = dataQueryStatus;

const AnalyticsTeamVolume = () => {
    const { workspaceId } = useSelector(
        (state) => state?.auth?.user?.workspace
    );

    const {
        currentAnalyticsFilter,
        analyticsVolume,
        currentAnalyticsCustomDate,
    } = useSelector((state) => state.analytics);
    const dispatch = useDispatch();

    const [analyticsData, setAnalyticsData] = useState([]);
    const [ticketVolumeData, setTicketVolumeData] = useState([]);

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(false);
    const ticketVolTimeMetric = useRef("Sec");

    const {
        analyticsRequestType,
        analyticsRequestDuration,
        analyticsRequestValue,
    } = currentAnalyticsFilter || {};

    const toastMessage = useContext(ToastContext);

    const hasTicketInfo =
        analyticsRequestType ===
        teamAnalyticsRequestFilters.TICKET_CLASSIFICATION;

    const updateRequest = (name, value) => {
        dispatch(
            updateCurrentAnalyticsFilter({
                [name]: value,
            })
        );
    };

    const handleSetOptions = async (options) => {
        console.log("handleSetOptions was called with", options)
        let stringRequestOption = options.map((o) => o.value).join(",");
        await setOptions(options);
        // await setSelectedOptions(options);

        if (analyticsVolume?.[analyticsRequestType]) {
            let persistedAnalyticsData =
                analyticsVolume?.[analyticsRequestType]?.[
                    analyticsRequestDuration
                ];
            if (persistedAnalyticsData) {
                setAnalyticsData(persistedAnalyticsData);
                setChartLoading(false);
            } else {
                setChartLoading(true);
            }
        } else {
            setChartLoading(true);
        }

        await getAnalyticsData(stringRequestOption);
    };

    const compileChartData = async (data, keyName, valName) => {
        if (data?.length > 0) {
            const dateSeries = getDatesBasedOnDuration(
                analyticsRequestDuration
            );

            const mainArrayData = [];

            const optionValues = options.map(({ value }) => {
                return value;
            });

            const newValsArray =
                analyticsRequestValue?.length > 0
                    ? analyticsRequestValue?.split(",")
                    : optionValues;

            // const strokeOptions = options.filter(({ value }) =>
            //     newValsArray.includes(value)
            // );

            // setSelectedOptions(strokeOptions);

            let { useMinuteAsTime, totalCounts } = computeDataFreq(
                analyticsData?.avgVolumeResponses,
                keyName,
                valName
            );

            ticketVolTimeMetric.current = useMinuteAsTime ? "Min" : "Sec";

            dateSeries?.forEach(async (dt) => {
                //assignedTeamId
                let valInstances = data.filter((ad) => {
                    return (
                        new Date(ad.dateValue).toDateString() ===
                        new Date(dt).toDateString()
                    );
                });

                let originalObj = Object.assign(
                    {},
                    ...newValsArray.map((y) => {
                        return { [y]: 0 };
                    })
                );

                let result = {};

                if (analyticsRequestType === "TICKET_SOLUTION_TYPE") {
                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((valData) => {
                            return arrangeSolutionTypeData(
                                "averageVolumeFixCount",
                                valData
                            );
                        })
                    );
                } else {
                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((o) => {
                            const naminng = o[keyName] || "all"
                            return {
                                [`${naminng}_FREQ`]: computerPercentage(
                                    o["totalCount"] || 0,
                                    totalCounts
                                ),
                                [`${naminng}`]: o[valName]
                                    ? useMinuteAsTime
                                        ? o[valName] / 60
                                        : o[valName]
                                    : 0,
                                originalData: o,
                            };
                        })
                    );
                }

                mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
            });

            return mainArrayData;
        }

        // setAnalyticsData(dateSeries)
    };

    const { startDate, endDate } =
        getCurrentAnalyticsCustomDates(
            currentAnalyticsFilter,
            currentAnalyticsCustomDate
        ) || {};

    const getAnalyticsData = async (preRequestVal) => {
        try {
            if (startDate === "" || endDate === "") {
                return;
            }

            setIsLoading(true);
            const commaAnalyticsValue = "";

            const url = apiRoute?.getTicketVolumeAnalytics;
            const res = await API.get(url, {
                // const res = await Axios.get(`http://localhost:7073/${url}`, {
                headers: {
                    workspaceid: workspaceId,
                },
                params: {
                    ...currentAnalyticsFilter,
                    analyticsRequestValue: commaAnalyticsValue,
                    startDate,
                    endDate,
                    analyticsRequestType: analyticsRequestType || undefined,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setAnalyticsData(data);
                setChartLoading(false);
                dispatch(
                    setAnalyticsChartData({
                        analyticsData: data,
                        analyticsRequestDuration,
                        analyticsRequestType,
                        analyticsChartTitle: "analyticsVolume",
                    })
                );
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(true);
            setChartLoading(false);
            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            let errorMssg = getErrorMessage(err);
            toastMessage(errorMssg, ERROR);
        }
    };

    const compileAnalyticsGroupData = async () => {
        const { avgVolumeResponses } = analyticsData;
        const compileTicketsVolume = await compileChartData(
            avgVolumeResponses,
            ANALYTICS_TEAM_VOL_REQ_TYPES?.[
                currentAnalyticsFilter?.analyticsRequestType
            ],
            "totalCount"
        );

        console.log("compileTicketsVolume_____>>>>>>", compileTicketsVolume);

        // assignedTeamId
        setTicketVolumeData(compileTicketsVolume);
    };

    useEffect(() => {
        compileAnalyticsGroupData();
        // eslint-disable-next-line
    }, [analyticsData]);


    useEffect(() => {
        getAnalyticsData();
        // eslint-disable-next-line
    }, []);
    
    return (
        <>
            <div className='padded__layout analytics-team-efficiency'>
                <AnalyticsFilters
                    defaultRequest={currentAnalyticsFilter}
                    updateRequest={updateRequest}
                    handleSubmit={getAnalyticsData}
                    isLoading={isLoading}
                    setSelectedOptions={setSelectedOptions}
                    currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                    {...{
                        options,
                        setOptions: handleSetOptions,
                    }}
                />

                <div>
                    <div className='analytics-team-efficiency__chart'>
                        <AnalyticsBarChart
                            chartTitle='Number of Tickets'
                            chartData={ticketVolumeData || []}
                            isLoading={chartLoading}
                            strokeData={
                                analyticsRequestType
                                    ? selectedOptions
                                    : analyticsTeamVolDefaultOptions
                            }
                            allStrokeData={options}
                            xDataKey1={"dateValue"}
                            yDataKey1={"all"}
                            xDomain2={[0, 100]}
                            yAxisLabel1={"Number of Tickets"}
                            xAxisLabel1={`Time`}
                            yAxisLabel2={"Total Volume"}
                            xAxisLabel2={"Freq"}
                            customUnit1={"ticket(s)"}
                            tooltipExtraMetrics={
                                analyticsTeamVolExtraTooltipMetrics
                            }
                            useDefaultOptions={
                                analyticsRequestType?.length === 0
                            }
                            hasTicketInfo={hasTicketInfo}
                            roundUpTooltipVal
                            hasFreqKey
                            isOverlapped
                            overviews={[
                                {
                                    title: "Resolved Tickets",
                                    text: analyticsData?.resultGroup
                                        ?.resolvedCount,
                                },
                                {
                                    title: "Unresolved",
                                    text: analyticsData?.resultGroup
                                        ?.unResolvedCount,
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnalyticsTeamVolume;
