export const ANALYTICS_TEAM_VOL_REQ_TYPES = {
    TEAM: "assignedTeamId",
    AGENT: "assignedTeamMemberId",
    TICKET_CLASSFICATION: "ticketClassification",
    TICKET_PHASE: "ticketPhase",
    TICKET_SOURCE: "ticketSource",
    TICKET_CAPSULES: "ticketInfoValue",
    TICKET_SOLUTION: "",
};

export const analyticsTeamVolDefaultOptions = [
    {
        label: "Number of Tickets",
        value: "all",
        name: "Number of Tickets",
        color: "green",
    },
];

export const analyticsTeamVolExtraTooltipMetrics = [
    {
        label: "External Fix Count",
        value: "externalFixCount",
    },
    {
        label: "Instructional Fix Count",
        value: "instructionalFixCount",
    },
    {
        label: "Internal Fix Count",
        value: "internalFixCount",
    },
];
