import React, { useState } from "react";
import DatePicker from "react-datepicker";
import CustomDatePickerInput from "./CustomDatePickerInput/CustomDatePickerInput";
import { localeDate } from "utils/helper";
import "./CustomDatePicker.scss";

export const CustomDatePicker = ({
    onChange,
    name,
    value,
    minDate,
    maxDate,
    startDate,
    endDate,
    hasError,
    placeholder,
    hasIcon,
    otherClassNames,
    selectsRange,
}) => {
    const [dateRange, setDateRange] = useState([startDate, endDate]);

    const handleChange = (dates) => {
        if (selectsRange) {
            setDateRange(dates);
            onChange?.(dates);
        } else {
            let dateString = localeDate(dates);
            onChange?.(dateString);
        }
    };

    return (
        <div className={`form-group`}>
            <DatePicker
                onChange={handleChange}
                className={`form-control ${
                    otherClassNames ? otherClassNames : ""
                } ${hasError ? `date__picker__error__border` : ``}`}
                name={name}
                value={value}
                popperPlacement='top-start'
                minDate={minDate}
                maxDate={maxDate}
                selectsRange={selectsRange}
                startDate={selectsRange ? dateRange[0] : null}
                endDate={selectsRange ? dateRange[1] : null}
                placeholderText={placeholder}
                customInput={
                    hasIcon && (
                        <CustomDatePickerInput
                            hasError={hasError}
                            otherClassNames={otherClassNames}
                        />
                    )
                }
            />
        </div>
    );
};
