import React from "react";
import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";
import "../AnalyticsBarHeader.scss";

const AnalyticsBarCardSkeleton = () => {
    return (
        <div className='analytics__bar__header__card'>
            <div className='bar__header__card__top mb-2 pt-1 pb-2'>
                <ShinyLoader width="80px" height="9px" />
            </div>
            <div className='bar__header__card__bottom pb-1'>
                <ShinyLoader width="60px" height="8px" />
                {/* <ShinyLoader width="60px" height="8px" /> */}
            </div>
        </div>
    );
};

const AnalyticsBarHeaderSkeleton = () => {
    return (
        <div className='analytics__bar__header'>
            <div className='analytics__bar__header__card-container'>
                <AnalyticsBarCardSkeleton />
                <AnalyticsBarCardSkeleton />
            </div>
            <div className='analytics__toggle'>
                <div className="average">AVG</div>
                <div className="percentage">%</div>
            </div>
        </div>
    );
};

export default AnalyticsBarHeaderSkeleton;