import React, { useState, useContext, useRef } from "react";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

import { updateCurrentAnalyticsFilter } from "store/analytics/actions";
import { apiRoute } from "services";
import { useSelector, useDispatch } from "react-redux";
import { MonthDay, getErrorMessage } from "utils/helper";
import { arrangeSolutionTypeData, getDatesBasedOnDuration } from "../common/helpers";
import { dataQueryStatus } from "utils";

import AnalyticsBarChart from "../common/AnalyticsBarChart/AnalyticsBarChart";
import { getCurrentAnalyticsCustomDates } from "../common/helpers";
import AnalyticsFilters from "../common/AnalyticsFilters/AnalyticsFilters";
import { teamAnalyticsRequestFilters } from "../common/enum";
import { useEffect } from "react";
import { ANALYTICS_TEAM_VOL_REQ_TYPES } from "../AnalyticsTeamVolume/enums";
import API from "services/lib/api";

const { ERROR } = dataQueryStatus;

const AccurateAnalyticsTeamEfficiency = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [chartLoading, setChartLoading] = useState(true);
    const [useAIAssistance, toggleAIAssistance] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [resolutionChartData, setResolutionChartData] = useState([]);
    const [fcrChartData, setFcrChartData] = useState([]);

    const [overallStats, setOverallStats] = useState({ agentAvg: 0, aiAvg: 0, avgResponsesOptions: [] });

    const toastMessage = useContext(ToastContext);
    const dispatch = useDispatch();

    const {
        currentAnalyticsFilter,
        currentAnalyticsCustomDate,
    } = useSelector((state) => state.analytics);

    const { startDate, endDate } = getCurrentAnalyticsCustomDates(
        currentAnalyticsFilter,
        currentAnalyticsCustomDate
    ) || {};

    const {
        analyticsRequestType,
        analyticsRequestDuration,
        analyticsRequestValue,
    } = currentAnalyticsFilter || {};


    const calculateResponseTimeFrequency = (instances, timeRange) => {
        if (!instances?.length || timeRange <= 0) return 0;

        // Sort time ranges and merge overlapping ones
        const timeRanges = instances
            .map(item => ({
                min: parseFloat(item.min_response_time_minutes),
                max: parseFloat(item.max_response_time_minutes)
            }))
            .filter(range => !isNaN(range.min) && !isNaN(range.max))
            .sort((a, b) => a.min - b.min);

        if (!timeRanges.length) return 0;

        // Merge overlapping ranges
        const mergedRanges = timeRanges.reduce((acc, curr) => {
            if (!acc.length) return [curr];
            const last = acc[acc.length - 1];

            if (curr.min <= last.max) {
                last.max = Math.max(last.max, curr.max);
                return acc;
            }
            return [...acc, curr];
        }, []);

        // Calculate total coverage
        const totalCoverage = mergedRanges.reduce((sum, range) =>
            sum + (range.max - range.min), 0);

        return Math.min((totalCoverage / timeRange) * 100, 100);
    };

    const hasTicketInfo = analyticsRequestType === teamAnalyticsRequestFilters.TICKET_CLASSIFICATION;
    const analyticsResultTimeMetric = useRef({
        averageFirstResponseTime: "Sec",
        averageResolutionTime: "Sec",
        averageResponseTime: "Sec",
        aiAverageFirstResponseTime: "Sec",
        aiAverageResponseTime: "Sec",
        total_response_time: "Hour",
        ai_response_time: "Sec",
    });

    const updateRequest = (name, value) => {
        dispatch(
            updateCurrentAnalyticsFilter({
                [name]: value,
            })
        );
    };

    const handleSetOptions = async (options) => {
        await setOptions(options);
    };

    const minutesToHours = value => Math.round((parseFloat(value) / 60) * 100) / 100;
    const calculateTrend = (dailyStats, dates) => {
        console.log({ dailyStats, dates });
        if (dates.length < 2) return { change: 0, trend: 'neutral' };

        // Get valid daily averages (non-zero values)
        const dailyAverages = dates.map(date => {
            const stats = dailyStats[date];
            return stats && stats.count > 0 ? (stats.total / stats.count) : null;
        }).filter(val => val !== null);

        console.log({ dailyAverages })
        if (dailyAverages.length < 2) return { change: 0, trend: 'neutral' };

        // Calculate moving average to smooth out fluctuations
        const movingAverage = dailyAverages.reduce((acc, curr, idx) => {
            if (idx === 0) return [curr];
            const prev = acc[acc.length - 1];
            return [...acc, (prev * 0.7 + curr * 0.3)];
        }, []);

        console.log({ movingAverage })

        // Calculate trend using first and last points
        const firstValue = movingAverage[0];
        const lastValue = movingAverage[movingAverage.length - 1];
        
        if (firstValue === 0) return { change: 0, trend: 'neutral' };
        
        const percentChange = ((lastValue - firstValue) / Math.abs(firstValue)) * 100;

        const result =  {
            change: Number.isFinite(percentChange) ? percentChange : 0,
            trend: percentChange > 1 ? 'up' : percentChange < -1 ? 'down' : 'neutral'
        };

        console.log({ result });

        return result;
    };

    const compileChartData = async (data, keyName, valName) => {
        if (!data || data.length === 0) return [];

        if (data?.length > 0) {
            const dateSeries = getDatesBasedOnDuration(
                analyticsRequestDuration
            );

            const mainArrayData = [];

            const optionValues = options.map(({ value }) => {
                return value;
            });

            const newValsArray =
                analyticsRequestValue?.length > 0
                    ? analyticsRequestValue?.split(",")
                    : optionValues;


            const overallStats = data.reduce((acc, item) => {
                const min = parseFloat(item.min_response_time_minutes);
                const max = parseFloat(item.max_response_time_minutes);


                return {
                    ...acc,
                    min: Math.min(acc.min, isNaN(min) ? Infinity : min),
                    max: Math.max(acc.max, isNaN(max) ? -Infinity : max),
                };
            }, {
                min: Infinity,
                max: -Infinity,

            });

            // Calculate averages directly from data array
            const averages = data.reduce((acc, item) => {
                const responseTime = minutesToHours(item[valName] || 0);
                const isAI = item.responder_type === "AI";
                const memberId = item[keyName];
                const date = new Date(item.date);

                // Track values by date for trend analysis
                const dateKey = date.toISOString().split('T')[0];
                if (!acc.dailyStats[dateKey]) {
                    acc.dailyStats[dateKey] = {
                        aiTotal: 0, aiCount: 0,
                        agentTotal: 0, agentCount: 0
                    };
                }

                if (isAI) {
                    acc.aiTotal += responseTime;
                    acc.aiCount++;
                    acc.dailyStats[dateKey].aiTotal += responseTime;
                    acc.dailyStats[dateKey].aiCount++;
                } else {
                    acc.agentTotal += responseTime;
                    acc.agentCount++;
                    acc.dailyStats[dateKey].agentTotal += responseTime;
                    acc.dailyStats[dateKey].agentCount++;
                }

                if (memberId) {
                    if (!acc.memberStats[memberId]) {
                        acc.memberStats[memberId] = { total: 0, count: 0, daily: {} };
                    }
                    acc.memberStats[memberId].total += responseTime;
                    acc.memberStats[memberId].count++;

                    // Track daily stats for each member
                    if (!acc.memberStats[memberId].daily[dateKey]) {
                        acc.memberStats[memberId].daily[dateKey] = { total: 0, count: 0 };
                    }
                    acc.memberStats[memberId].daily[dateKey].total += responseTime;
                    acc.memberStats[memberId].daily[dateKey].count++;
                }

                return acc;
            }, {
                aiTotal: 0,
                aiCount: 0,
                agentTotal: 0,
                agentCount: 0,
                memberStats: {},
                dailyStats: {}
            });

            const sortedDates = dateSeries.map(d => new Date(d).toISOString().split('T')[0]).sort();

            const calculatedAverages = {
                agentAvg: averages.agentCount > 0 ?
                    (averages.agentTotal / averages.agentCount) : 0,
                aiAvg: averages.aiCount > 0 ?
                    (averages.aiTotal / averages.aiCount) : 0,
                trends: {
                    agent: calculateTrend(
                        Object.entries(averages.dailyStats).reduce((acc, [date, stats]) => ({
                            ...acc,
                            [date]: { total: stats.agentTotal, count: stats.agentCount }
                        }), {}),
                        sortedDates
                    ),
                    ai: calculateTrend(
                        Object.entries(averages.dailyStats).reduce((acc, [date, stats]) => ({
                            ...acc,
                            [date]: { total: stats.aiTotal, count: stats.aiCount }
                        }), {}),
                        sortedDates
                    )
                },
                memberAverages: Object.entries(averages.memberStats).reduce((acc, [memberId, stats]) => ({
                    ...acc,
                    [memberId]: {
                        average: stats.count > 0 ? (stats.total / stats.count) : 0,
                        trend: calculateTrend(stats.daily, sortedDates)
                    }
                }), {})
            };
         
            const optionsWithAverages = selectedOptions.map(option => ({
                ...option,
                average: calculatedAverages.memberAverages[option.value] || 0
            }));

            console.log("optionsWithAverages", optionsWithAverages);
            // Combine both state updates into one
            setOverallStats({
                ...calculatedAverages,
                avgResponsesOptions: optionsWithAverages
            });
          
            const timeRange = overallStats.max - overallStats.min;

            // ticketVolTimeMetric.current = useMinuteAsTime ? "Min" : "Sec";

            dateSeries?.forEach(async (dt) => {
                //assignedTeamId

                let valInstances = data.filter((ad) =>
                    new Date(ad.date).toDateString() === new Date(dt).toDateString()
                );

                const dailyFrequency = calculateResponseTimeFrequency(valInstances, timeRange);

                let originalObj = Object.assign(
                    {},
                    ...newValsArray.map((y) => {
                        return { [y]: 0 };
                    })
                );

                let result = {};

                if (analyticsRequestType === "TICKET_SOLUTION_TYPE") {
                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((valData) => {
                            return arrangeSolutionTypeData(
                                "averageResponseTime",
                                valData
                            );
                        })
                    );
                } else {
                    // Calculate total and AI-specific values
                    const totalValue = valInstances.reduce((sum, o) =>
                        sum + minutesToHours(o[valName] || 0), 0);

                    const aiValue = valInstances
                        .filter(o => o.responder_type === "AI")
                        .reduce((sum, o) =>
                            sum + minutesToHours(o[valName] || 0), 0);

                    result = Object.assign(
                        originalObj,
                        ...valInstances.map((o) => {
                            const naminng = o[keyName] || "all"
                            return {
                                [`${naminng}_FREQ`]: Math.round(dailyFrequency),
                                valInstances,
                                [`${naminng}`]: minutesToHours(parseInt(o[valName]) || 0),
                                originalData: o,
                                total_response_time: parseFloat(totalValue.toFixed(2)),
                                y_total_response_time: Math.round(totalValue),
                                ai_response_time: parseFloat(aiValue.toFixed(2)),
                                total_response_time_FREQ: Math.round(dailyFrequency),
                            };
                        })
                    );
                }

                mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
            });

            return mainArrayData;
        }

    };

    const compileResolutionData = async (data, keyName) => {
        if (!data || data.length === 0) return [];

        // Calculate resolution stats and trends
        const resolutionStats = data.reduce((acc, item) => {
            const dateKey = new Date(item.date).toISOString().split('T')[0];
            const memberId = item[keyName];

            if (!acc.dailyStats[dateKey]) {
                acc.dailyStats[dateKey] = { total: 0, handleTime: 0, count: 0 };
            }

            if (memberId && memberId !== 'all') {
                if (!acc.memberStats[memberId]) {
                    acc.memberStats[memberId] = { total: 0, handleTime: 0, count: 0, daily: {} };
                }
                if (!acc.memberStats[memberId].daily[dateKey]) {
                    acc.memberStats[memberId].daily[dateKey] = { total: 0, handleTime: 0, count: 0 };
                }

                const resolutionTime = parseFloat(item.avg_resolution_time_hours || 0);
                const handleTime = parseFloat(item.avg_handle_time_hours || 0);

                acc.memberStats[memberId].total += resolutionTime;
                acc.memberStats[memberId].handleTime += handleTime;
                acc.memberStats[memberId].count++;
                acc.memberStats[memberId].daily[dateKey].total += resolutionTime;
                acc.memberStats[memberId].daily[dateKey].handleTime += handleTime;
                acc.memberStats[memberId].daily[dateKey].count++;
            }

            return acc;
        }, { memberStats: {}, dailyStats: {} });

        // Update overallStats with resolution metrics
        setOverallStats(prev => ({
            ...prev,
            resolutionMetrics: {
                memberStats: Object.entries(resolutionStats.memberStats).reduce((acc, [memberId, stats]) => ({
                    ...acc,
                    [memberId]: {
                        average: stats.count > 0 ? stats.total / stats.count : 0,
                        handleTime: stats.count > 0 ? stats.handleTime / stats.count : 0,
                        trend: calculateTrend(stats.daily, Object.keys(resolutionStats.dailyStats).sort())
                    }
                }), {})
            }
        }));

        const dateSeries = getDatesBasedOnDuration(analyticsRequestDuration);
        const mainArrayData = [];

        const optionValues = options.map(({ value }) => value);
        const newValsArray = analyticsRequestValue?.length > 0
            ? analyticsRequestValue?.split(",")
            : optionValues;

        dateSeries?.forEach(dt => {
            let valInstances = data.filter(ad =>
                new Date(ad.date).toDateString() === new Date(dt).toDateString()
            );

            let originalObj = Object.assign(
                {},
                ...newValsArray.map(y => ({ [y]: 0 }))
            );

            const totalResolutionTime = valInstances.reduce((sum, o) =>
                sum + parseFloat(o.avg_resolution_time_hours || 0), 0);

            console.log({valInstances })
            console.log("totalResolutionTime", totalResolutionTime, "avg_handle_time")
            const avgHandleTime = valInstances.reduce((sum, o) =>
                sum + parseFloat(o.avg_handle_time_hours || 0), 0);

            const result = Object.assign(
                originalObj,
                ...valInstances.map(o => ({
                    [`${o[keyName] || "all"}`]: Number((parseFloat(o.avg_resolution_time_hours || 0)).toFixed(2)),
                    total_resolution_time: Number((totalResolutionTime).toFixed(2)),
                    avg_handle_time: Number((avgHandleTime).toFixed(2)),
                    same_day_resolutions: o.same_day_resolutions || 0,
                    closed_tickets: o.closed_tickets_count || 0
                }))
            );

            mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
        });

        return mainArrayData;
    };

    const compileFCRData = async (data, keyName) => {
        if (!data || data.length === 0) return [];

        const fcrStats = data.reduce((acc, item) => {
            const dateKey = new Date(item.dateValue).toISOString().split('T')[0];
            const memberId = item[keyName];

            if (!acc.dailyStats[dateKey]) {
                acc.dailyStats[dateKey] = { fcrRate: 0, count: 0 };
            }

            // Initialize 'all' stats if not exists
            if (!acc.memberStats['all']) {
                acc.memberStats['all'] = { total: 0, count: 0, daily: {} };
            }
            if (!acc.memberStats['all'].daily[dateKey]) {
                acc.memberStats['all'].daily[dateKey] = { total: 0, count: 0 };
            }

            // Add to 'all' totals
            const fcrRate = parseFloat(item.daily_fcr_rate || 0);
            acc.memberStats['all'].total += fcrRate;
            acc.memberStats['all'].count++;
            acc.memberStats['all'].daily[dateKey].total += fcrRate;
            acc.memberStats['all'].daily[dateKey].count++;

            // Add to individual member stats
            if (memberId && memberId !== 'all') {
                if (!acc.memberStats[memberId]) {
                    acc.memberStats[memberId] = { total: 0, count: 0, daily: {} };
                }
                if (!acc.memberStats[memberId].daily[dateKey]) {
                    acc.memberStats[memberId].daily[dateKey] = { total: 0, count: 0 };
                }

                acc.memberStats[memberId].total += fcrRate;
                acc.memberStats[memberId].count++;
                acc.memberStats[memberId].daily[dateKey].total += fcrRate;
                acc.memberStats[memberId].daily[dateKey].count++;
            }

            return acc;
        }, { memberStats: {}, dailyStats: {} });

        console.log({ fcrStats })
        // Update overallStats with FCR metrics
        setOverallStats(prev => ({
            ...prev,
            fcrMetrics: {
                memberStats: Object.entries(fcrStats.memberStats).reduce((acc, [memberId, stats]) => ({
                    ...acc,
                    [memberId]: {
                        average: stats.count > 0 ? stats.total / stats.count : 0,
                        trend: calculateTrend(stats.daily, Object.keys(fcrStats.dailyStats).sort())
                    }
                }), {})
            }
        }));


        const dateSeries = getDatesBasedOnDuration(analyticsRequestDuration);
        const mainArrayData = [];

        const optionValues = options.map(({ value }) => value);
        const newValsArray = analyticsRequestValue?.length > 0
            ? analyticsRequestValue?.split(",")
            : optionValues;
        dateSeries?.forEach(dt => {
            let valInstances = data.filter(ad =>
                new Date(ad.dateValue).toDateString() === new Date(dt).toDateString()
            );

            let originalObj = Object.assign(
                {},
                ...newValsArray.map(y => ({ [y]: 0 }))
            );

            const aggregatedValues = valInstances.reduce((acc, o) => {
                const key = o[keyName] || "all";
                if (!acc[key]) {
                    acc[key] = {
                        fcr_rate: 0,
                        total_tickets: 0,
                        fcr_resolved: 0,
                        fcr_tickets: 0,
                        non_fcr_tickets: 0,
                        count: 0
                    };
                }
                // Ensure we're working with numbers by using Number() instead of parseFloat
                acc[key].fcr_rate += Number(o.daily_fcr_rate) || 0;
                acc[key].total_tickets += Number(o.total_tickets) || 0;
                acc[key].fcr_resolved += Number(o.fcr_resolved_tickets) || 0;
                acc[key].fcr_tickets += Number(o.fcr_tickets) || 0;
                acc[key].non_fcr_tickets += Number(o.non_fcr_tickets) || 0;
                acc[key].count++;
                return acc;
            }, {});

            // Create final result with averaged FCR rates
            const result = Object.assign(
                originalObj,
                ...Object.entries(aggregatedValues).map(([key, values]) => ({
                    [key]: values.fcr_rate / values.count,
                    fcr_rate: values.fcr_rate / values.count,
                    total_tickets: values.total_tickets,
                    fcr_resolved: values.fcr_resolved,
                    fcr_tickets: values.fcr_tickets,
                    non_fcr_tickets: values.non_fcr_tickets
                }))
            );

            mainArrayData.push({ ...result, dateValue: MonthDay(dt) });
        });

        return mainArrayData;
    };

    const getAnalyticsData = async () => {
        try {
            if (startDate === "" || endDate === "") {
                return;
            }
            setIsLoading(true);
            setChartLoading(true);
            const commaAnalyticsValue = "";

            const url = apiRoute?.getTicketsResponseTimeAnalytics;
            const res = await API.get(url, {
                params: {
                    ...currentAnalyticsFilter,
                    analyticsRequestValue: commaAnalyticsValue,
                    startDate,
                    endDate,
                    analyticsRequestType: analyticsRequestType || undefined,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;

                const compileAverageResponse = await compileChartData(
                    data?.responseTimes,
                    ANALYTICS_TEAM_VOL_REQ_TYPES?.[
                    currentAnalyticsFilter?.analyticsRequestType
                    ],
                    'avg_response_time_minutes'
                );

                const resolutionData = await compileResolutionData(
                    data?.resolutionTimes,
                    ANALYTICS_TEAM_VOL_REQ_TYPES?.[currentAnalyticsFilter?.analyticsRequestType]
                );

                const fcrData = await compileFCRData(
                    data?.fcrRates,
                    ANALYTICS_TEAM_VOL_REQ_TYPES?.[currentAnalyticsFilter?.analyticsRequestType]
                );

                setResolutionChartData(resolutionData);
                setFcrChartData(fcrData);
                setChartData(compileAverageResponse);

            }
        } catch (err) {
            console.log(err);

            if (
                err?.message ===
                "Cannot destructure property 'baseURL' of 'originalRequest' as it is undefined."
            ) {
                return "";
            }
            let errorMssg = getErrorMessage(err);
            toastMessage(errorMssg, ERROR);
        } finally {
            setIsLoading(false);
            setChartLoading(false);
        }
    };

    useEffect(() => {
        getAnalyticsData()
        // eslint-disable-next-line
    }, [])


    return (
        <div className='padded__layout analytics-team-efficiency'>
            <AnalyticsFilters
                defaultRequest={currentAnalyticsFilter}
                currentAnalyticsCustomDate={currentAnalyticsCustomDate}
                updateRequest={updateRequest}
                handleSubmit={getAnalyticsData}
                isLoading={isLoading}
                selectedOptions={selectedOptions}
                setSelectedOptions={setSelectedOptions}
                {...{
                    options,
                    setOptions: handleSetOptions,
                }}
                showAIButton={true}
                toggleAIAssistance={(state) =>
                    toggleAIAssistance(!useAIAssistance)
                }
            />

            <div>
                <div className='analytics-team-efficiency__chart'>
                    <AnalyticsBarChart
                        chartTitle='Response Time Analysis'
                        chartData={chartData}
                        isLoading={chartLoading}
                        strokeData={[
                            {
                                name: "Agent Response Time",
                                value: "total_response_time",
                                color: "#6837ef"
                            },
                            ...selectedOptions
                        ]}
                        hasTicketInfo={hasTicketInfo}
                        allStrokeData={options}

                        xDataKey1="dateValue"
                        yDataKey1="total_response_time"
                        yDataKey2="y_total_response_time"


                        yAxisLabel1="Average Response Time (hours)"
                        xAxisLabel1="Date"

                        yAxisLabel2="Average Response Time (hours)"
                        xAxisLabel2="Frequency"

                        xDomain2={[0, 100]}
                        hasFreqKey

                        useAIAssistance={useAIAssistance}
                     
                        tooltipFields={[
                            { key: 'total_response_time', label: 'TotaL Response Time', unit: 'hrs' },
                            { key: 'ai_response_time', label: 'Average AI Response', unit: 'hrs' },
                            ...selectedOptions.map((option) => (
                                {
                                    key: option.value,
                                    label: `${option.label}'s Response Time`,
                                    unit: 'hrs'
                                } 
                            ))
                        ]}
                        useDefaultOptions={analyticsRequestType?.length === 0}
                        isOverlapped
                        skipCompute
                        overviews={[
                            {
                                title: "Agent Response Time",
                                text: `${Number(overallStats.agentAvg || 0).toFixed(2)}`,
                                isHigh: overallStats.trends?.agent?.trend === 'up',
                                percent: Number(overallStats.trends?.agent?.change || 0).toFixed(2)
                            },
                            ...(overallStats?.avgResponsesOptions || []).map(option => ({
                                title: `${option.label}`,
                                text: `${Number(option.average?.average || 0).toFixed(2)}`,
                                isHigh: overallStats.memberAverages?.[option.value]?.trend?.trend === 'up',
                                percent: Number(overallStats.memberAverages?.[option.value]?.trend?.change || 0).toFixed(2)
                            })),
                            useAIAssistance && {
                                title: "AI Response",
                                text: `${Number(overallStats.aiAvg || 0).toFixed(2)}`,
                                isHigh: overallStats.trends?.ai?.trend === 'up',
                                percent: Number(overallStats.trends?.ai?.change || 0).toFixed(2)
                            }
                        ].filter(Boolean)}

                        timeMetric={analyticsResultTimeMetric.current[useAIAssistance ? 'ai_response_time' : 'total_response_time']}
                    />
                </div>

                {/* Resolution Time Chart */}
                <div className='analytics-team-efficiency__chart'>
                    <AnalyticsBarChart
                        chartTitle='Resolution Time Analysis'
                        chartData={resolutionChartData}
                        isLoading={chartLoading}
                        strokeData={[
                            {
                                name: "Resolution Time",
                                value: "total_resolution_time",
                                color: "#34C759"
                            },
                            ...selectedOptions
                        ]}
                     
                        tooltipFields={[
                            { key: 'total_resolution_time', label: 'Total Resolution Time', unit: 'hrs' },
                            { key: 'avg_handle_time', label: 'Total Agent Handle Time', unit: 'hrs' },
                            { key: 'closed_tickets', label: 'Closed Tickets', unit: '' },
                            ...selectedOptions.map((option) => (
                                {
                                    key: option.value,
                                    label: `${option.label}'s Tickets Resolution Time`,
                                    unit: 'hrs'
                                } 
                            ))
                        ]}
                        hasTicketInfo={hasTicketInfo}
                        allStrokeData={options}
                        xDataKey1="dateValue"
                        yDataKey1="total_resolution_time"
                        yDataKey2="avg_handle_time"
                        yAxisLabel1="Average Resolution Time (hours)"
                        xAxisLabel1="Date"
                        yAxisLabel2="Average Handle Time (hours)"
                        xAxisLabel2="Time"
                        useAIAssistance={useAIAssistance}
                        isOverlapped
                        skipCompute
                        overviews={[
                            {
                                title: "Resolution Time",
                                text: `${Number(overallStats.resolutionMetrics?.memberStats?.all?.average || 0).toFixed(2)}`,
                                isHigh: overallStats.resolutionMetrics?.memberStats?.all?.trend?.trend === 'up',
                                percent: Number(overallStats.resolutionMetrics?.memberStats?.all?.trend?.change || 0).toFixed(2)
                            },
                            {
                                title: "Handle Time",
                                text: `${Number(overallStats.resolutionMetrics?.memberStats?.all?.handleTime || 0).toFixed(2)}`,
                                isHigh: true,
                                percent: 0
                            },
                            ...(selectedOptions.map(option => ({
                                title: `${option.label} Resolution`,
                                text: `${Number(overallStats.resolutionMetrics?.memberStats?.[option.value]?.average || 0).toFixed(2)}`,
                                isHigh: overallStats.resolutionMetrics?.memberStats?.[option.value]?.trend?.trend === 'up',
                                percent: Number(overallStats.resolutionMetrics?.memberStats?.[option.value]?.trend?.change || 0).toFixed(2)
                            })))
                        ]}
                    />
                </div>

                {/* FCR Chart */}
                <div className='analytics-team-efficiency__chart'>
                    <AnalyticsBarChart
                        chartTitle='First Contact Resolution Analysis'
                        chartData={fcrChartData}
                        isLoading={chartLoading}
                        strokeData={[
                            {
                                name: "FCR Rate",
                                value: "fcr_rate",
                                color: "#FF9500"
                            },
                            ...selectedOptions
                        ]}
                        hasTicketInfo={hasTicketInfo}
                        allStrokeData={options}
                        xDataKey1="dateValue"
                        yDataKey1="fcr_rate"
                        yDataKey2="total_tickets"
                        yAxisLabel1="FCR Rate (%)"
                        xAxisLabel1="Date"
                        yAxisLabel2="Total Tickets"
                        xAxisLabel2="Count"
                        useAIAssistance={useAIAssistance}
                        isOverlapped
                        skipCompute
                        overviews={[
                            {
                                title: "FCR Rate",
                                text: `${Number(overallStats.fcrMetrics?.memberStats?.all?.average || 0).toFixed(2)}%`,
                                isHigh: overallStats.fcrMetrics?.memberStats?.all?.trend?.trend === 'up',
                                percent: Number(overallStats.fcrMetrics?.memberStats?.all?.trend?.change || 0).toFixed(2)
                            },
                            ...(selectedOptions.map(option => ({
                                title: `${option.label} FCR`,
                                text: `${Number(overallStats.fcrMetrics?.memberStats?.[option.value]?.average || 0).toFixed(2)}%`,
                                isHigh: overallStats.fcrMetrics?.memberStats?.[option.value]?.trend?.trend === 'up',
                                percent: Number(overallStats.fcrMetrics?.memberStats?.[option.value]?.trend?.change || 0).toFixed(2)
                            })))
                        ]}

                        tooltipFields={[
                            {
                                label: "FCR Rate",
                                key: "fcr_rate",
                                unit: "%"
                            },
                            {
                                label: "Total Tickets",
                                key: "total_tickets",
                                unit: ""
                            },
                            {
                                label: "FCR Resolved",
                                key: "fcr_resolved",
                                unit: ""
                            },
                            {
                                label: "FCR Tickets",
                                key: "fcr_tickets",
                                unit: ""
                            },
                            {
                                label: "Non-FCR Tickets",
                                key: "non_fcr_tickets",
                                unit: ""
                            },
                            ...selectedOptions.map((option) => (
                                {
                                    key: option.value,
                                    label: `${option.label}'s FCR Rate`,
                                    unit: '%'
                                } 
                            ))
                        ]}
                    />
                </div>
            </div>
        </div>
    )
}

export default AccurateAnalyticsTeamEfficiency;